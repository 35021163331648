import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { tours } from "../../data";
import { СontainedButton } from "../Buttons/ContainedButton/ContainedButton";
import "./Tours.css";

export default function Tours() {

  return (
    <div className="toursContainer" id="tours">
      <div className="toursTitleBox">
        <p className="descriptor">
          <FormattedMessage id="descriptorTours" />
        </p>
        <p className="titleTours">
          <div className="titleToursFirst">
            <span>
              <FormattedMessage id="titleToursFirst" />
            </span>
            <span className="titleToursColor">
              <FormattedMessage id="titleToursColor1" />
            </span>
          </div>
          <div className="titleToursSecond">
            <span className="titleToursColor">
              <FormattedMessage id="titleToursColor2" />
            </span>
            <span>
              <FormattedMessage id="titleTours" />
            </span>
          </div>
        </p>
      </div>
      <div className="toursList">
        {tours.map((tour) => (
          <div key={tour.id} className={"toursBlock"}>
            <Accordion expanded={tour.show} className="toursOfferList">
              <AccordionSummary
                className="tourOfferTitleContainer"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <span className="tourOfferTitle">
                  <FormattedMessage id={tour.title} />
                </span>
              </AccordionSummary>
              <AccordionDetails className="tourImgContainer">
                  {tour.img.map((img, index) => (
                    <img src={img} alt="..." className="imgInTour" key={index}/>
                  ))}
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </div>
      <СontainedButton>
        <a
          href={`https://wa.me/79030031642?text=Здравствуйте,+я+хочу+узнать+подробнее+о+турах`}
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="corporateButton" />
        </a>
      </СontainedButton>
    </div>
  );
}
