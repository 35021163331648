import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useNavigate } from "react-router-dom";
import { practices } from "../../data/practices";
import { getCheckoutUrl } from "../../firebase";
import { isLoggedIn } from "../../storage/session";
import { scrollTo } from "../../utils";
import "./Practices.css";

export default function Practices({ responsive, mobileView, browserView }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onClick = async (price, counter, title) => {
    if (!isLoggedIn()) {
      navigate("/login");
    } else {
      setLoading(true);
      await getCheckoutUrl(price, counter, true, title);
      setLoading(false);
    }
  };

  return (
    <div>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="descriptor">
        <FormattedMessage id="section_practices" />
      </div>
      {browserView && (
        <div className="practicesBox">
          {practices.map((type, index) => (
            <Card
              key={"practiceCard" + index}
              className="practiceCard"
              style={{ backgroundImage: `url(${type.img})` }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="practiceCardTitle"
                >
                  <FormattedMessage id={`${type.title}`} />
                </Typography>
              </CardContent>
              <CardActions className="practiceCardFooter">
                <Typography variant="body2" className="practiceCardDescription">
                  <FormattedMessage id={type.description} />
                </Typography>
                {type.title === "practice_telegram" ? (
                  <Button
                    size="small"
                    variant="contained"
                    className="buttonInPracticeCard"
                    onClick={() => onClick("price_1R5PGeDBhuDCu8Sdtr8YhEvl", 1, 'Подписку на телеграм-канал на 1 месяц')}
                  >
                      <FormattedMessage id={"buttonInPriceCard"} />
                  </Button>
                ) : (
                  <Button
                    size="small"
                    variant="contained"
                    className="buttonInPracticeCard"
                    onClick={() => scrollTo("prices")}
                  >
                    <FormattedMessage id={"buttonInPriceCard"} />
                  </Button>
                )}
              </CardActions>
            </Card>
          ))}
        </div>
      )}
      {mobileView && (
        <div className="practicesBox">
          <Carousel
            swipeable={true}
            draggable={false}
            responsive={responsive}
            ssr={false}
            infinite={true}
            autoPlay={false}
            partialVisible={true}
            keyBoardControl={true}
            containerClass="carousel-container"
            itemClass="practiceCardCarousel"
            removeArrowOnDeviceType={["mobile"]}
          >
            {practices.map((type, index) => (
              <div key={"practice" + index} className="practiceCardContent">
                <Card
                  className="practiceCard"
                  key={"practiceCard" + index}
                  style={{ backgroundImage: `url(${type.img})` }}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="practiceCardTitle"
                    >
                      <FormattedMessage id={`${type.title}`} />
                    </Typography>
                  </CardContent>
                  <CardActions className="practiceCardFooter">
                    <Typography
                      variant="body2"
                      className="practiceCardDescription"
                    >
                      <FormattedMessage id={type.description} />
                    </Typography>
                    <Button
                      size="small"
                      variant="contained"
                      className="buttonInPracticeCard"
                    >
                      <Link to={"/booking"}>
                        <FormattedMessage id={"buttonInPracticeCard"} />
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
}
