import {
  addDoc,
  collection,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
import { createDocument } from "../firebase";
import { getSession } from "../storage/session";
import { firebaseDB } from "./firebase";

export const getCheckoutUrl = async (price, counter, isTelegram, title) => {
  const user = getSession();
  const checkoutSessionRef = collection(
    firebaseDB,
    "customers",
    user.uid,
    `checkout_sessions`
  );

  const docRef = await addDoc(checkoutSessionRef, {
    line_items: [{ price, quantity: 1 }],
    mode: "payment",
    success_url: window.location.origin, // TODO: add our success URL here
    cancel_url: window.location.origin, // TODO: add our error URL here
  });

  onSnapshot(docRef, (snap) => {
    const { error, url } = snap.data();
    if (url) {
      window.location.assign(url); // redirect to payment link
      createUserPaymentCounter(user, counter, isTelegram);
      let message = `Привет! Вы купили ${title}. До встречи на коврике!`;
      alert(message);
    }
  });
};

const createUserPaymentCounter = async (user, counter, isTelegram) => {
  await createDocument("paidClasses", {
    userId: user.uid,
    userName: user.displayName,
    counter,
  });
  if (isTelegram) {
    window.location.assign("https://t.me/+DK8LpVIKafY0ODY6");
  }
};

export const editUserPaymentCounter = async (counter, docId) => {
  try {
    const newCollection = doc(firebaseDB, "paidClasses", docId);
    await updateDoc(newCollection, {
      counter,
    });
  } catch (error) {
    console.error(error);
  }
};
