import maldives2024_1 from "../assets/maldives2024_1.JPG";
import maldives2024_2 from "../assets/maldives2024_2.JPG";
import maldives2024_3 from "../assets/maldives2024_3.JPG";
import maldives2024_4 from "../assets/maldives2024_4.JPG";
import maldives2025_1 from "../assets/maldives2025_1.JPG";
import maldives2025_2 from "../assets/maldives2025_2.JPG";
import maldives2025_3 from "../assets/maldives2025_3.JPG";
import maldives2025_4 from "../assets/maldives2025_4.JPG";
import nepal_1 from "../assets/nepal_1.JPG";
import nepal_2 from "../assets/nepal_2.JPG";
import nepal_3 from "../assets/nepal_3.JPG";
import nepal_4 from "../assets/nepal_4.JPG";
import sardinia_1 from "../assets/sardinia_1.JPG";
import sardinia_2 from "../assets/sardinia_2.JPG";
import sardinia_3 from "../assets/sardinia_3.JPG";
import sardinia_4 from "../assets/sardinia_4.JPG";
import france_1 from "../assets/france_1.JPG";
import france_2 from "../assets/france_2.JPG";
import france_3 from "../assets/france_3.JPG";
import france_4 from "../assets/france_4.JPG";

export const tours = [
  {
    id: 1,
    title: "maldivesRetreat2024",
    img: [maldives2024_1, maldives2024_2, maldives2024_3, maldives2024_4],
  },
  {
    id: 2,
    title: "sardiniaRetreat",
    img: [sardinia_1, sardinia_2, sardinia_3, sardinia_4],
  },
  {
    id: 3,
    title: "nepalRetreat",
    img: [nepal_1, nepal_2, nepal_3, nepal_4],
  },
  {
    id: 4,
    title: "franceRetreat",
    img: [france_1, france_2, france_3, france_4],
  },
  {
    id: 5,
    title: "maldivesRetreat2025",
    img: [maldives2025_1, maldives2025_2, maldives2025_3, maldives2025_4],
  },
];
