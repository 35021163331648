import NightlightIcon from "@mui/icons-material/Nightlight";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import { format } from "date-fns";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import frLocale from "date-fns/locale/fr";
import ruLocale from "date-fns/locale/ru";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { showAllBookings, showAvailableTime } from "../../api";
import { getSession, isLoggedIn } from "../../storage/session";
import { getCurrentDay } from "../../utils";
import { СontainedButton } from "../Buttons/ContainedButton/ContainedButton";
import "./Booking.css";
import { getDocuments } from "../../firebase";
import { editUserPaymentCounter } from "../../firebase/stripePayment";
import { createBooking } from "../../api";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

const localeMap = {
  en: enLocale,
  fr: frLocale,
  ru: ruLocale,
  de: deLocale,
};

const maskMap = {
  fr: "__/__/____",
  en: "__/__/____",
  ru: "__.__.____",
  de: "__.__.____",
};

export default function Booking() {
  const navigate = useNavigate();

  const user = getSession();

  const [selectedDate, setSelectedDate] = useState(
    format(Date.now(), "yyyy-MM-dd")
  );
  const [trainingInfo, setTrainingInfo] = useState({});
  const [trainingData, setTrainingData] = useState([]);
  const [locale, setLocale] = useState("en");
  const [bookings, setBookings] = useState([]);
  const [slots, setSlots] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const isAlreadyBook = useRef(false);
  const [openModal, setOpenModal] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    (async () => {
      const bookData = await showAllBookings();
      setBookings(bookData);

      const availableSlots = await showAvailableTime();
      setSlots(availableSlots);
    })();
  }, []);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);

  const selectLocale = (newLocale) => {
    setLocale(newLocale);
  };

  const booking = useMemo(() => {
    const hash = {};

    bookings?.forEach((book) => {
      const bookingDate = format(new Date(book.date), "yyyy-MM-dd");

      if (!hash[bookingDate]) {
        hash[bookingDate] = [book.time];
      } else {
        hash[bookingDate].push(book.time);
      }
    });

    return hash;
  }, [bookings]);

  useEffect(() => {
    const day = getCurrentDay(selectedDate);

    const data = slots[day]
      ?.map(({ time, groupType, trainingType, personCount, format }) => ({
        time,
        groupType,
        trainingType,
        personCount,
        format,
        isNotAvailable: false,
      }))
      .map((td) => {
        let count = 0;
        booking[selectedDate]?.forEach((book) => {
          if (book === td.time) {
            count++;
          }
        });
        if (count >= td.personCount) {
          td.isNotAvailable = true;
        }
        return td;
      });

    setTrainingData(data);
  }, [selectedDate, slots, booking]);

  const getDisabledDates = (date) => {
    const day = getCurrentDay(date);

    return day === "Saturday";
  };

  const handleDatePicker = (value) => {
    const date = format(value, "yyyy-MM-dd");
    setTrainingInfo({});
    setSelectedDate(date);
  };

  const handleTimePicker = (value) => {
    checkIsAlreadyBook(bookings);
    setTrainingInfo(value);
  };

  const checkIsAlreadyBook = (bookData) => {
    const bookingsInfo = bookData.filter((book) => user.uid === book.userId);
    const hasCurrentBooking = bookingsInfo.some((book) => {
      return book.date === selectedDate;
    });
    if (hasCurrentBooking) {
      isAlreadyBook.current = true;
    } else {
      isAlreadyBook.current = false;
    }
  };

  //TODO: если записался на практику, то выводить сообщение о том, что записался
  const handleBooking = async () => {
    if (!Object.keys(trainingInfo).length) {
      setOpenDialog(true);
    } else {
      const paidClasses = await getDocuments("paidClasses");
      if (paidClasses[0].counter === 0) {
        setOpenModal(true);
      }
      if (user.uid === paidClasses[0].userId) {
        editUserPaymentCounter(paidClasses[0].counter - 1, paidClasses[0].id);
        createBooking(selectedDate, trainingInfo.time, user);
        checkIsAlreadyBook(bookings);
      }

      navigate("/cabinet");
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseModal = () => setOpenModal(false);

  return (
    <div className="boxBooking">
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormattedMessage id={"needPayment"} />
          </Typography>
        </Box>
      </Modal>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage id={"confirmBooking"} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <FormattedMessage id={"resetPasswordCancel"} />
          </Button>
          <Button onClick={handleClose} autoFocus>
            <FormattedMessage id={"buttonBooking"} />
          </Button>
        </DialogActions>
      </Dialog>
      <div className="containerBooking">
        <div className="bookDate">
          <h2>
            <FormattedMessage id="bookDate" />
          </h2>
          <div className="picker">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={localeMap[locale]}
            >
              <div>
                <ToggleButtonGroup
                  className="buttonsLanguage"
                  value={locale}
                  exclusive
                  sx={{ mb: 2, display: "block" }}
                >
                  {Object.keys(localeMap).map((localeItem, index) => (
                    <ToggleButton
                      key={index}
                      value={localeItem}
                      onClick={() => selectLocale(localeItem)}
                    >
                      {localeItem}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                <StaticDatePicker
                  mask={maskMap[locale]}
                  orientation="landscape"
                  openTo="day"
                  value={selectedDate}
                  shouldDisableDate={getDisabledDates}
                  onChange={handleDatePicker}
                  renderInput={(props) => <TextField {...props} />}
                  minDate={new Date("2022-04-04")}
                />
              </div>
            </LocalizationProvider>
            <div className="timeButtons">
              {trainingData?.map(
                (
                  { time, groupType, trainingType, isNotAvailable, format },
                  index
                ) => {
                  return (
                    <Tooltip
                      key={index}
                      title={
                        isNotAvailable ? (
                          <FormattedMessage id={"emptyBooking"} />
                        ) : (
                          ""
                        )
                      }
                      placement="right"
                    >
                      {trainingType === "morning" ? (
                        <span className="buttonInBooking">
                          <Button
                            disabled={isNotAvailable}
                            onClick={() =>
                              handleTimePicker({ time, groupType, format })
                            }
                            startIcon={<WbSunnyIcon />}
                          >
                            {format === "zoom" ? (
                              <span>
                                <FormattedMessage id="pricesGroupPracticeZoom" />
                              </span>
                            ) : (
                              <span>
                                <FormattedMessage id="antigravityClass" />
                              </span>
                            )}
                            <span>{time}</span>
                            {/* <span>{format}</span> */}
                          </Button>
                        </span>
                      ) : (
                        <span className="buttonInBooking">
                          <Button
                            disabled={isNotAvailable}
                            onClick={() =>
                              handleTimePicker({ time, groupType, format })
                            }
                            startIcon={<NightlightIcon />}
                          >
                            {format === "zoom" ? (
                              <span>
                                <FormattedMessage id="pricesGroupPracticeZoom" />
                              </span>
                            ) : (
                              <span>
                                <FormattedMessage id="antigravityClass" />
                              </span>
                            )}
                            <span>{time}</span>
                            {/* <span>{format}</span> */}
                          </Button>
                        </span>
                      )}
                    </Tooltip>
                  );
                }
              )}
            </div>
          </div>
        </div>
        <div className="containerBookSum">
          <h2>
            <FormattedMessage id="bookingSummary" />
          </h2>
          <p className="dateAndPracticeP">
            <FormattedMessage id="dateBooking" /> : {selectedDate}{" "}
            {trainingInfo.time} {trainingInfo.format}
          </p>
          {isLoggedIn() ? (
            <Tooltip
              title={
                isAlreadyBook.current ? (
                  <FormattedMessage id={"alreadyBooking"} />
                ) : (
                  ""
                )
              }
            >
              <span onClick={handleBooking}>
                <Button
                  className={"containedButton"}
                  disabled={
                    isAlreadyBook.current || !Object.keys(trainingInfo).length
                  }
                >
                  <FormattedMessage id="buttonBooking" />
                </Button>
              </span>
            </Tooltip>
          ) : (
            <>
              <p className="dateAndPracticeP">
                <FormattedMessage id="member" />?
              </p>
              <Link href="/login" className="dateAndPracticeP">
                <FormattedMessage id="loginBooking" />
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
