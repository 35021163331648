import Button from "@mui/material/Button";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { practicesType } from "../../data/index";
import { getCheckoutUrl } from "../../firebase";
import { isLoggedIn } from "../../storage/session";
import "./Prices.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function Prices({ responsive }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onClick = async (price, counter, title) => {
    if (!isLoggedIn()) {
      navigate("/login");
    } else {
      setLoading(true);
      await getCheckoutUrl(price, counter, false, title);
      setLoading(false);
    }
  };

  return (
    <div>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="pricesTitleBox">
        <p className="descriptor">
          <FormattedMessage id="descriptorPrices" />
        </p>
        <p className="titlePrices">
          <span>
            <FormattedMessage id="titlePricesFirst" />
          </span>
          <span className="titlePricesColor">
            <FormattedMessage id="titlePricesColor" />
          </span>
          <FormattedMessage id="titlePrices" />
        </p>
      </div>
      <Carousel
        swipeable={true}
        draggable={false}
        responsive={responsive}
        ssr={false}
        infinite={true}
        autoPlay={false}
        partialVisible={true}
        keyBoardControl={true}
        containerClass="carousel-container"
        itemClass="priceCard"
        removeArrowOnDeviceType={["mobile"]}
      >
        {practicesType.map((practice) => (
          <div key={"practice" + practice.id} className="priceCardContent">
            <div className="priceCardText">
              <span className="priceCardTitle">
                <FormattedMessage id={practice.title} />
              </span>
              <span className="priceCardFirstText">
                <FormattedMessage id={practice.title + "Duration"} />
              </span>
              <span className="priceCardSecondText">
                <FormattedMessage id={practice.title + "Price"} />
              </span>
            </div>
            <div className="buttonInPriceCardContainer">
              <Button
                onClick={() => onClick(practice.id, practice.counter, practice.titleForPay)}
                size="small"
                variant="contained"
                className="buttonInPriceCard"
              >
                <FormattedMessage id={"buttonInPriceCard"} />
              </Button>
              <span className="priceCardThirdText">
                <FormattedMessage id={'durationInPrice'} />
              </span>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
