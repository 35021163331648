// export const practices = [
//   {
//     id: "1",
//     type: "individual",
//   },
//   {
//     id: "2",
//     type: "group",
//   },
// ];

import group from "../assets/groupPractice.jpg";
import personal from "../assets/personalPractice.jpg";
import telegram from "../assets/telegram.jpg";
import antigravity from "../assets/antigravity.jpg";

export const practices = [
  { title: "practice_group", img: group, description: "practice_2" },
  { title: "practice_telegram", img: telegram, description: "practice_3" },
  { title: "practice_antigravity", img: antigravity, description: "practice_4" },
  { title: "practice_individual", img: personal, description: "practice_1" },
];
