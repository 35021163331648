import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import mainImage from "../../assets/mainImage.png";
import { СontainedButton } from "../Buttons/ContainedButton/ContainedButton";
import NavigationMenu from "../NavigatonMenu/NavigationMenu";
import "./Cover.css";
import { scrollTo } from "../../utils";

export default function Cover({
  currentLocale,
  handleChange,
  mobileView,
  browserView,
}) {
  return (
    <div>
      {browserView && (
        <div
          className="coverContainer"
          style={{
            backgroundImage: `url(${mainImage})`,
          }}
        >
          <NavigationMenu
            currentLocale={currentLocale}
            handleChange={handleChange}
            browserView={browserView}
            mobileView={mobileView}
          />
          <div className="cover">
            <span className="coverTitle">
              <FormattedMessage id={"coverTitle"} />
            </span>
            <span className="coverText">
              <FormattedMessage id={"coverText"} />
            </span>
            <div onClick={() => scrollTo('practices')}>
            <СontainedButton  onClick={() => scrollTo('practices')}>
                <FormattedMessage id={"corporateButton"} />
            </СontainedButton>
            </div>
          </div>
        </div>
      )}
      {mobileView && (
        <div>
          <NavigationMenu
            currentLocale={currentLocale}
            handleChange={handleChange}
            browserView={browserView}
            mobileView={mobileView}
          />
          <img src={mainImage} alt="..." className="coverImg" />
          <div className="cover">
            <span className="coverTitle">
              <FormattedMessage id={"coverTitle"} />
            </span>
            <span className="coverText">
              <FormattedMessage id={"coverText"} />
            </span>
            <СontainedButton>
              <Link to={"/booking"}>
                <FormattedMessage id={"buttonBookNow"} />
              </Link>
            </СontainedButton>
          </div>
        </div>
      )}
    </div>
  );
}
