export const practicesType = [
  {
    id: "price_1Qzb5cDBhuDCu8Sd3HgCYBvB",
    title: "pricesGroupPracticeZoomFirstVisit",
    titleForPay: "Групповой класс в zoom первое посещение",
    cost: "20CHF",
    counter: 1,
  },
  {
    id: "price_1QzawdDBhuDCu8SdLVOZuOGm",
    title: "pricesGroupPracticeZoom",
    titleForPay: "Групповой класс в zoom",
    cost: "25CHF",
    counter: 1,
  },
  {
    id: "price_1QzawcDBhuDCu8SdfrYFzUaj",
    title: "subscription4practices",
    titleForPay: "Абонемент на 4 класса в zoom",
    cost: "90CHF",
    counter: 4,
  },
  {
    id: "price_1QzawaDBhuDCu8SdedxXcYWt",
    title: "subscription6practices",
    titleForPay: "Абонемент на 6 классов в zoom",
    cost: "120CHF",
    counter: 6,
  },
  {
    id: "price_1QzawZDBhuDCu8SdSrHp7wHM",
    title: "subscription8practices",
    titleForPay: "Абонемент на 8 классов в zoom",
    cost: "150CHF",
    counter: 8,
  },
  {
    id: "price_1QzazbDBhuDCu8SdKs8Jxdfl",
    title: "pricesAntigravityFirstVisit",
    titleForPay: "Групповой класс antigravity yoga первое посещение",
    cost: "25CHF",
    counter: 1,
  },
  {
    id: "price_1Qzb4vDBhuDCu8SdAjCfupBs",
    title: "pricesAntigravityOneClass",
    titleForPay: "Групповой класс antigravity yoga одно посещение",
    cost: "35CHF",
    counter: 1,
  },
  {
    id: "price_1Qzb23DBhuDCu8SdGkbKxUGp",
    title: "subscription5PracticesAntigravity",
    titleForPay: "Абонемент на 5 классов antigravity yoga",
    cost: "160CHF",
    counter: 5,
  },
  {
    id: "price_1Qzb3rDBhuDCu8SdMmohaLDz",
    title: "subscription10PracticesAntigravity",
    titleForPay: "Абонемент на 10 классов antigravity yoga",
    cost: "310CHF",
    counter: 10,
  },
  {
    id: "price_1Qzb4QDBhuDCu8SdMGUQ0WTS",
    title: "subscription15PracticesAntigravity",
    titleForPay: "Абонемент на 15 классов antigravity yoga",
    cost: "380CHF",
    counter: 15,
  },
  {
    id: "price_1R5PGeDBhuDCu8Sdtr8YhEvl",
    title: "pricesTelegramClub",
    titleForPay: "Клуб в telegram",
    cost: "45CHF",
    counter: 1,
  },
  {
    id: "price_1QzawXDBhuDCu8Sd3Ff5VUoX",
    title: "pricesPersonalLesson",
    titleForPay: "Персональный класс оффлайн",
    cost: "110CHF",
    counter: 1,
  },
  {
    id: "price_1QzawWDBhuDCu8SdNhnqo6Pn",
    title: "pricesPersonalLessonZoom",
    titleForPay: "Персональный класс в zoom",
    cost: "80CHF",
    counter: 1,
  },
  {
    id: "price_1QzawUDBhuDCu8SdW38C9qwT",
    title: "pricesPrenatal",
    titleForPay: "Персональный класс Prenatal оффлайн",
    cost: "110CHF",
    counter: 1,
  },
  {
    id: "price_1QzawSDBhuDCu8SdXnwmOelL",
    title: "pricesPrenatalZoom",
    titleForPay: "Персональный класс Prenatal в zoom",
    cost: "80CHF",
    counter: 1,
  },
 
];
